import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getCurrentUser, getUser, logout } from "@features/Auth/authSlice";
import { useHandleSearchSubmit } from "@hooks/useHandleSearchSubmit";
import MoreIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Button,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Link as MuiLink,
  Paper,
  Toolbar,
  Typography
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import logoutSVG from "@static/icons/logout.svg";
// import messaging from "@static/icons/messaging.svg";
// import notification from "@static/icons/notification.svg";
import Logo from "@static/tissuesharelogo_teal.png";
import MobileLogo from "@static/tissuesharelogo_teal_icon_only.png";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    custom: true;
  }
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.secondary.main, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.25)
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40ch"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50ch"
    }
  }
}));

export default function TSAppBar() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  // set up for search bar
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const options = [
    {
      text: `${searchTerm} in Public feed`,
      page: "dashboard/home"
    },
    {
      text: `${searchTerm} in Institution feed`,
      page: "dashboard"
    },
    {
      text: `${searchTerm} in Institution database`,
      page: "dashboard/institution-database"
    },
    {
      text: `${searchTerm} in Lab and People`,
      page: "dashboard/institution-search"
    }
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setShowDropdown(inputValue.length > 0);
  };

  const handleSearchSubmit = useHandleSearchSubmit(setSearchTerm);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setShowDropdown(false);
      handleSearchSubmit(searchTerm, event);
    }
  };

  const handleOptionClick = (page: string) => {
    if (page === "dashboard" || page === "dashboard/home") {
      navigate(`/${page}`);
      handleSearchSubmit(searchTerm);
    } else {
      const encodedSearchTerm = searchTerm.replace(/ /g, "+");
      navigate(`/${page}?query=${encodedSearchTerm}`);
    }
    setShowDropdown(false);
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <Typography
          key={index}
          component='span'
          fontWeight='bold'
          style={{ margin: "0 2px" }}
        >
          {part}
        </Typography>
      ) : (
        <Typography key={index} component='span'>
          {part}
        </Typography>
      )
    );
  };

  // set up for current user profile
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const currentUser = useSelector(getUser);
  const currentUserId = currentUser.id;

  const isLoggedIn = Boolean(currentUserId !== undefined);

  const handleLoginLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (isLoggedIn) {
      const logoutResponse = await dispatch(logout());
      if (logoutResponse.payload.logoutUrl) {
        return (window.location.href = logoutResponse.payload.logoutUrl);
      }

      navigate("/");
    } else {
      navigate("/get-started");
    }
  };

  // most of this is boilerplate from Material UI libarary
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() =>
          isLoggedIn ? navigate("/dashboard/home") : navigate("/")
        }
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={home} alt='Home' style={{ width: 20, height: 20 }} />{" "}
        <Typography fontSize={12}>Home</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/about")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={about} alt='About' style={{ width: 20, height: 20 }} />{" "}
        <Typography fontSize={12}>About</Typography>
      </MenuItem>
      {/* <MenuItem
        onClick={() => navigate("")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img
          src={messaging}
          alt='Messaging'
          style={{ width: 20, height: 12 }}
        />{" "}
        <Typography fontSize={8}>Messaging</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img
          src={notification}
          alt='Messaging'
          style={{ width: 20, height: 12 }}
        />{" "}
        <Typography fontSize={8}>Notifications</Typography>
      </MenuItem> */}
      <MenuItem onClick={handleLoginLogout}>
        <ListItemText>{isLoggedIn ? "Log Out" : "Log In"}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar
        position='fixed'
        color='custom'
        sx={{
          top: 0,
          boxShadow: "none",
          borderBottom: `1px solid ${APP_COLORS.lightGray}`,
          zIndex: (theme) => theme.zIndex.drawer + 1 // to make sure it stays above other components
        }}
      >
        <Box
          sx={{
            px: { xs: 2, md: 0 }
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              p: 0
            }}
          >
            <MuiLink
              href='/'
              underline='none'
              variant='h6'
              noWrap
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: { xs: 100, sm: "fit-content" },
                overflow: "hidden"
              }}
            >
              <Box
                component='img'
                sx={{
                  width: "100%",
                  objectFit: "contain",
                  height: { xs: 30 },
                  display: { xs: "none", md: "block" }
                }}
                alt='TissueShare logo'
                src={Logo}
              />
              <Box
                component='img'
                sx={{
                  height: { xs: 30, lg: 40 },
                  display: { xs: "block", md: "none" }
                }}
                alt='TissueShare logo'
                src={MobileLogo}
              />
            </MuiLink>
            {isLoggedIn ? (
              <div>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon color='primary' />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder='Search for tissues, animal models, and more'
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyPress={handleKeyPress}
                  />

                  {showDropdown && (
                    <Paper
                      style={{
                        position: "absolute",
                        top: "100%",
                        width: "100%",
                        zIndex: 1
                      }}
                    >
                      <List>
                        {options.map((option, index) => (
                          <ListItem
                            key={index}
                            button
                            onClick={() => handleOptionClick(option.page)}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <SearchIcon color='primary' />
                            <ListItemText
                              primary={highlightText(option.text)}
                              primaryTypographyProps={{
                                style: { display: "flex", alignItems: "center" }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  )}
                </Search>{" "}
              </div>
            ) : null}
            <Box
              sx={{
                width: "auto",
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 3
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center"
                }}
              >
                <Button
                  size='small'
                  color='inherit'
                  href={isLoggedIn ? "/dashboard/home" : "/"}
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <img
                    src={home}
                    alt='About'
                    style={{ width: 20, height: 20 }}
                  />{" "}
                  <Typography fontSize={12}>Home</Typography>
                </Button>
                <Button
                  size='small'
                  color='inherit'
                  href='/about'
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <img
                    src={about}
                    alt='About'
                    style={{ width: 20, height: 20 }}
                  />{" "}
                  <Typography fontSize={12}>About</Typography>
                </Button>
                {/* <Button
                  size='small'
                  color='inherit'
                  href=''
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <img
                    src={messaging}
                    alt='Messaging'
                    style={{ width: 20, height: 12 }}
                  />{" "}
                  <Typography fontSize={8}>Messaging</Typography>
                </Button>
                <Button
                  size='small'
                  color='inherit'
                  href=''
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <img
                    src={notification}
                    alt='Notifications'
                    style={{ width: 20, height: 12 }}
                  />{" "}
                  <Typography fontSize={8}>Notifications</Typography>
                </Button> */}
              </Box>
              <Box
                sx={{
                  gap: "10px",
                  display: { xs: "none", md: "flex" },
                  alignItems: "center"
                }}
              >
                <Typography fontSize={12}>
                  <Button
                    size='small'
                    aria-label={isLoggedIn ? "log out" : "log in"}
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleLoginLogout}
                    variant={!isLoggedIn ? "outlined" : "contained"}
                    sx={{ width: "max-content" }}
                  >
                    {isLoggedIn ? (
                      <>
                        <span>Logout</span>
                        <img
                          src={logoutSVG}
                          alt='logout'
                          style={{ width: 14, height: 14 }}
                        />
                      </>
                    ) : (
                      <span>Login</span>
                    )}
                  </Button>
                </Typography>

                <Typography fontSize={12}>
                  {isLoggedIn ? (
                    <></>
                  ) : (
                    <Button
                      size='small'
                      aria-label='register button'
                      aria-controls={menuId}
                      aria-haspopup='true'
                      href='/get-started'
                      variant='contained'
                      sx={{ width: "max-content" }}
                    >
                      Sign Up
                    </Button>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Button
                sx={{
                  p: 0,
                  minWidth: "unset"
                }}
                size='small'
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
              >
                <MoreIcon color='primary' />
              </Button>
            </Box>
          </Toolbar>
          {renderMobileMenu}
        </Box>
      </AppBar>
    </Box>
  );
}
