export const constants = {
  animalModel: "animal_model",
  animal_models: "animal_models",
  cell_line: "cell_line",
  cellLine: "cell_line",
  biospecimen: "biospecimen",
  antibody: "antibody",
  plasmid: "plasmid",
  equipment: "equipment",
  other: "other",
  institutionalDBSources:
    "user_post_share,lab_member_upload,super_admin_upload",
  lab_sources: "lab_member_upload,super_admin_upload",
  homeResource: "user_post_share,user_post_request",
  labUpload: "lab_member_upload",
  home: "home",
  profile: "profile",
  institution: "institution",
  jax: "jax",
  mgi: "mgi",
  manual_entry: "manual_entry"
};

export const RESEND_TIMEOUT = 50;
