import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

export const getConfigs = createAsyncThunk(
  "config/getConfig",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const res = await api.get("/config");
      return res.data.data;
    } catch (error) {
      const err = getError(error);
      return rejectWithValue(err);
    }
  }
);

interface InitialState {
  APP_ENVIRONMENT: "development" | "staging" | "production";
  SEARCH_ENABLED: boolean;
}

const initialState: InitialState = {
  APP_ENVIRONMENT: "development",
  SEARCH_ENABLED: true
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigs.fulfilled, (state, action) => {
        state.APP_ENVIRONMENT = action.payload.APP_ENVIRONMENT;
        state.SEARCH_ENABLED = action.payload.SEARCH_ENABLED;
      })
      .addCase(getConfigs.rejected, () => {
        enqueueSnackbar({
          variant: "error",
          message: "Could not load app config, try again later"
        });
      });
  }
});

const { name } = configSlice;

const getSlice = (state: RootState) => state[name];

export const selectAppConfig = createSelector(getSlice, (slice) => slice);
