import React, { ElementType, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserBlock from "@components/UserBlock/UserBlock";
import { getUser } from "@features/Auth/authSlice";
import { getLabInfo } from "@features/Lab/labslice";
import useRequireSession from "@hooks/useRequireSession";
import { Add } from "@mui/icons-material";
import { Button, Divider, Stack, Typography } from "@mui/material";
import donate from "@static/icons/donate-icon.svg";
import home from "@static/icons/home.svg";
import labSlab from "@static/icons/labicon.svg";
import university from "@static/icons/portal-uni-Icon.svg";
import magnifier from "@static/icons/sidebar-magni-Icon.svg";
import people from "@static/icons/user-group.svg";
import userIcon from "@static/icons/user-icon.svg";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import DashboardBody from "./DashboardBody";
import DashboardNavigation from "./DashboardNavigation";
import DashboardProvider from "./DashboardProvider";
import DashboardSidebar from "./DashboardSidebar";

interface DashboardRouteProps {
  redirectUnauthorized: boolean;
  component: ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const DashboardRoute: FC<DashboardRouteProps> = ({
  component: Component,
  redirectUnauthorized = false,
  ...rest
}) => {
  const [userId] = useRequireSession({
    redirect: redirectUnauthorized
  });

  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const labId: string = currentUser.labId;
  const isLoggedIn = Boolean(currentUser.id !== undefined);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getLabInfo(labId));
    }
  }, [dispatch, labId]);

  return (
    <DashboardProvider>
      {userId ? (
        <DashboardSidebar>
          <Divider sx={{ marginBottom: 4 }} />
          <DashboardNavigation>
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                Home
              </Typography>
              <DashboardNavigation item href={`/dashboard/home`} icon={home}>
                Public Feed
              </DashboardNavigation>
              <DashboardNavigation
                item
                href={`/dashboard/member/${userId}`}
                icon={userIcon}
              >
                My Profile
              </DashboardNavigation>
              <DashboardNavigation item href={"/dashboard/create"} button>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{
                    borderRadius: 3,
                    px: 1
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"}>
                    <Add sx={{ width: "17px" }} className='mr-1' />
                    <Typography pr={1}>Create a post</Typography>
                  </Stack>
                </Button>
              </DashboardNavigation>
            </Stack>
            <Divider />
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                My Institution
              </Typography>
              <DashboardNavigation item href={"/dashboard"} icon={university}>
                Recent Activity
              </DashboardNavigation>
              <DashboardNavigation
                item
                href='/dashboard/institution-database'
                icon={magnifier}
              >
                Institutional Database
              </DashboardNavigation>
              <DashboardNavigation
                item
                href={`/dashboard/lab?lab_id=${labId}`}
                icon={labSlab}
              >
                My Lab&apos;s Profile
              </DashboardNavigation>
              <DashboardNavigation
                item
                href='/dashboard/institution-search'
                icon={people}
              >
                Lab & People Search
              </DashboardNavigation>
            </Stack>
            <Divider />
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                Animal Facility
              </Typography>
              <DashboardNavigation item href='/dashboard/donate' icon={donate}>
                Donate Mice For Training
              </DashboardNavigation>
            </Stack>
          </DashboardNavigation>
          <Divider sx={{ my: 3 }} />
          <UserBlock currentUser={currentUser} />
        </DashboardSidebar>
      ) : null}
      <DashboardBody>
        <Component {...rest} />
      </DashboardBody>
    </DashboardProvider>
  );
};

export default DashboardRoute;
